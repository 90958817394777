import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import "../assets/styles/components/_footer.css";

const Footer = () => {
  const { translations } = useContext(LanguageContext);

  return (
    <footer className="footer">
      <p>{translations.footer.copyright}</p>
    </footer>
  );
};

export default Footer;
