import React from "react";

const NotFound = () => {
  return (
    <section id="not-found">
      <h2>404 - Not Found</h2>
      <p>
        Sorry, the page you are looking for might have been removed or is
        temporarily unavailable.
      </p>
    </section>
  );
};

export default NotFound;
