// src/data/skillsData.js
import {
  FaJsSquare,
  FaReact,
  FaHtml5,
  FaCss3Alt,
  FaNodeJs,
  FaPython,
  FaJava,
  FaDatabase,
  FaAws,
  FaGitAlt,
  FaGithub,
  FaDocker,
  FaLinux,
} from "react-icons/fa"; // Import icons from react-icons
import {
  SiExpress,
  SiMongodb,
  SiCsharp,
  SiC,
  SiNetlify,
  SiHeroku,
} from "react-icons/si";

const skills = [
  {
    name: "JavaScript",
    icon: FaJsSquare, // Use the imported icon
    level: 90, // Represents 90%
  },
  {
    name: "React",
    icon: FaReact, // Use the imported icon
    level: 85, // Represents 85%
  },
  {
    name: "Express",
    icon: SiExpress, // Correct icon for Express
    level: 80, // Represents 80%
  },
  {
    name: "MongoDB",
    icon: SiMongodb, // Correct icon for MongoDB
    level: 75, // Represents 75%
  },
  {
    name: "HTML5",
    icon: FaHtml5, // Correct icon for HTML5
    level: 90, // Represents 90%
  },
  {
    name: "CSS3",
    icon: FaCss3Alt, // Correct icon for CSS3
    level: 90, // Represents 90%
  },
  {
    name: "Node.js",
    icon: FaNodeJs, // Correct icon for Node.js
    level: 80, // Represents 80%
  },

  {
    name: "Python",
    icon: FaPython, // Correct icon for Python
    level: 70, // Represents 70%
  },
  {
    name: "C",
    icon: SiC, // Correct icon for C
    level: 65, // Represents 65%
  },
  {
    name: "C#",
    icon: SiCsharp, // Correct icon for C#
    level: 75, // Represents 75%
  },
  {
    name: "Java",
    icon: FaJava, // Correct icon for Java
    level: 70, // Represents 70%
  },
  {
    name: "Database",
    icon: FaDatabase, // Correct icon for Database
    level: 80, // Represents 80%
  },
  {
    name: "AWS",
    icon: FaAws, // Correct icon for AWS
    level: 75, // Represents 75%
  },
  {
    name: "Git",
    icon: FaGitAlt, // Correct icon for Git
    level: 85, // Represents 85%
  },
  {
    name: "GitHub",
    icon: FaGithub, // Correct icon for GitHub
    level: 85, // Represents 85%
  },
  {
    name: "Docker",
    icon: FaDocker, // Correct icon for Docker
    level: 80, // Represents 80%
  },
  {
    name: "Linux",
    icon: FaLinux, // Correct icon for Linux
    level: 75, // Represents
  },
  {
    name: "Heroku",
    icon: SiHeroku, // Correct icon for Heroku
    level: 80, // Represents 80%
  },
  {
    name: "Netlify",
    icon: SiNetlify, // Correct icon for Netlify
    level: 80, // Represents 80%
  },
];

export default skills;
