import React, { useEffect } from "react";
import "../assets/styles/components/_thank_you.css";

const ThankYou = () => {
  useEffect(() => {
    if (window.location.search.includes("success=true")) {
      alert("Your message has been sent successfully!");
    }
  }, []);

  return (
    <section className="thank-you-section">
      <div className="container">
        <h1>Thank You!</h1>
        <p>
          Your message has been successfully sent. I will get back to you soon!
        </p>
        <a href="/" className="btn btn-primary">
          Back to Home
        </a>
      </div>
    </section>
  );
};

export default ThankYou;
