import React from "react";

const Resume = () => {
  return (
    <section id="resume">
      <h2>Resume</h2>
      <p>
        Download my resume{" "}
        <a href="/resume.pdf" download>
          here
        </a>
        .
      </p>
    </section>
  );
};

export default Resume;
