import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { FaArrowRight } from "react-icons/fa";
import "../assets/styles/components/_hero.css";

const Hero = () => {
  const { translations } = useContext(LanguageContext);

  return (
    <section id="home" className="hero-section">
      <div className="hero-content">
        <h1 className="hero-title">{translations.hero.title}</h1>
        <p className="hero-description">{translations.hero.description}</p>
        <div className="hero-buttons">
          <a href="#projects" className="btn hero-btn-primary">
            {translations.hero.exploreWork}{" "}
            <FaArrowRight className="btn-icon" />
          </a>
          <a href="#contact" className="btn hero-btn-secondary">
            {translations.hero.connect} <FaArrowRight className="btn-icon" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
