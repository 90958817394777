// src/data/projectsData.js
// Using require to load images from the src directory
import project1Image from "../assets/images/projects/project1.jpg";
import project2Image from "../assets/images/projects/project2.jpg";

const projects = [
  {
    title: {
      en: "Kinam Website",
      no: "Kinam Nettsted",
    },
    description: {
      en: "Kinam is a comprehensive platform that provides up-to-date information and services related to multiple industries. It offers an intuitive user experience with a modern design that caters to the needs of a wide audience. The platform focuses on delivering detailed content and services that help users stay informed and connected.",
      no: "Kinam er en omfattende plattform som gir oppdatert informasjon og tjenester relatert til flere industrier. Den tilbyr en intuitiv brukeropplevelse med et moderne design som imøtekommer behovene til et bredt publikum. Plattformen fokuserer på å levere detaljerte innhold og tjenester som hjelper brukerne med å holde seg informert og tilkoblet.",
    },
    image: project1Image, // Correctly load image from src folder
    link: "https://www.kinam.net",
  },
  {
    title: {
      en: "Cybermend Website",
      no: "Cybermend Nettsted",
    },
    description: {
      en: "Cyber Mend is a modern web development platform specializing in creating custom-built websites for businesses of all sizes. This platform is built using React and leverages a serverless architecture, providing flexibility, scalability, and security. Cyber Mend focuses on creating SEO-optimized, user-friendly, and visually appealing websites that enhance the online presence of businesses. It offers fast performance with modern web technologies, aiming to drive customer engagement and business growth.",
      no: "Cyber Mend er en moderne plattform for webutvikling som spesialiserer seg på å lage skreddersydde nettsteder for bedrifter av alle størrelser. Plattformen er bygget med React og utnytter en serverløs arkitektur, som gir fleksibilitet, skalerbarhet og sikkerhet. Cyber Mend fokuserer på å lage SEO-optimaliserte, brukervennlige og visuelt tiltalende nettsteder som forbedrer bedriftenes tilstedeværelse på nett. Den tilbyr rask ytelse med moderne webteknologier for å øke kundens engasjement og forretningsvekst.",
    },
    image: project2Image, // Correctly load image from src folder
    link: "https://www.cybermend.no",
  },
  // Add more projects here
];

export default projects;
