import React from "react";
import timelineData from "../data/timelineData";
import "../assets/styles/components/_time_line.css";

const Timeline = () => {
  return (
    <section id="timeline" className="timeline-section">
      <h2 className="timeline-title">My Academic Journey</h2>
      <div className="timeline">
        {timelineData.map((item, index) => (
          <div
            className={`timeline-item ${index % 2 === 0 ? "left" : "right"}`}
            key={index}
          >
            <div className="timeline-content">
              <h3>{item.title}</h3>
              <h2>{item.year}</h2>
              <p>{item.description}</p>

              {/* Courses Section */}
              <div className="course-list">
                <h4>Courses:</h4>
                <ul>
                  {item.courses.map((course, courseIndex) => (
                    <li key={courseIndex} className="course-item">
                      <strong>{course.name}</strong>:
                      <span className="course-description">
                        - {course.description}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              {item.githubLink && (
                <a
                  href={item.githubLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn time-line-btn-primary"
                >
                  View Project on GitHub
                </a>
              )}
            </div>
          </div>
        ))}
        <div className="timeline-line"></div>
      </div>
    </section>
  );
};

export default Timeline;
