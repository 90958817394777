import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { FaBars, FaTimes, FaGlobe } from "react-icons/fa";
import "../assets/styles/components/_header.css";

const Header = () => {
  const { translations, toggleLanguage } = useContext(LanguageContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showNav, setShowNav] = useState(true); // Control visibility of navbar

  // Handle toggle menu
  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowNav(false); // Hide navbar when scrolling down
      } else {
        setShowNav(true); // Show navbar when scrolling up
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <header className={`header ${showNav ? "" : "hide-nav"}`}>
      <div className="container">
        <div className="logo">
          <a href="/">DEV_EF_</a>
        </div>
        <nav className={`nav ${isMenuOpen ? "nav-open" : ""}`}>
          <ul className="nav-list">
            <li className="nav-item">
              <a href="#home" onClick={handleToggleMenu}>
                {translations.header.home}
              </a>
            </li>
            <li className="nav-item">
              <a href="#about" onClick={handleToggleMenu}>
                {translations.header.about}
              </a>
            </li>
            <li className="nav-item">
              <a href="#projects" onClick={handleToggleMenu}>
                {translations.header.projects}
              </a>
            </li>
            <li className="nav-item">
              <a href="#skills" onClick={handleToggleMenu}>
                {translations.header.skills}
              </a>
            </li>
            <li className="nav-item">
              <a href="#contact" onClick={handleToggleMenu}>
                {translations.header.contact}
              </a>
            </li>
            <li className="nav-item toggle-lang" onClick={toggleLanguage}>
              <FaGlobe size={18} style={{ marginRight: "5px" }} />
              {translations.header.toggleLanguage}
            </li>
          </ul>
        </nav>
        <div className="menu-toggle" onClick={handleToggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
    </header>
  );
};

export default Header;
