import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Resume from "./pages/Resume";
import ThankYou from "./pages/ThankYou";
import NotFound from "./pages/NotFound";
import enTranslations from "./assets/translations/en.json";
import noTranslations from "./assets/translations/no.json";
import Header from "./components/Header";

function App() {
  const [language, setLanguage] = useState("en");

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "en" ? "no" : "en"));
  };

  const translations = language === "en" ? enTranslations : noTranslations;

  return (
    <Router>
      <Header
        currentLang={language}
        toggleLanguage={toggleLanguage}
        translations={translations}
      />
      <Routes>
        <Route path="/" element={<Home translations={translations} />} />
        <Route
          path="/resume"
          element={<Resume translations={translations} />}
        />
        <Route
          path="/thank-you"
          element={<ThankYou translations={translations} />}
        />
        <Route path="*" element={<NotFound translations={translations} />} />
      </Routes>
    </Router>
  );
}

export default App;
