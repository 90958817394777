import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { FaEnvelope, FaPhone, FaLinkedin, FaGithub } from "react-icons/fa";
import "../assets/styles/components/_contact.css";

const Contact = () => {
  const { translations } = useContext(LanguageContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(new FormData(form)).toString(),
    })
      .then(() => (window.location.href = "/thank-you"))
      .catch((error) => alert(error));
  };

  return (
    <section id="contact" className="contact-section">
      <div className="contact-container">
        <h2 className="contact-title">{translations.contact.title}</h2>
        <div className="contact-content">
          <div className="contact-info">
            <h3>{translations.contact.infoTitle}</h3>
            <p>
              <FaEnvelope className="contact-icon" /> contact@cybermend.no
            </p>
            <p>
              <FaPhone className="contact-icon" /> +0047 45 06 10 47
            </p>
            <div className="social-links">
              <a
                href="https://www.linkedin.com/in/efrem-mickael"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="social-icon" />
              </a>
              <a
                href="https://github.com/EfremMIc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGithub className="social-icon" />
              </a>
            </div>
          </div>
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            onSubmit={handleSubmit}
            className="contact-form"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input
              type="text"
              name="name"
              placeholder={translations.contact.namePlaceholder}
              required
            />
            <input
              type="email"
              name="email"
              placeholder={translations.contact.emailPlaceholder}
              required
            />
            <textarea
              name="message"
              placeholder={translations.contact.messagePlaceholder}
              required
            ></textarea>
            <button type="submit" className="btn contact-btn-primary">
              {translations.contact.submitButton}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
