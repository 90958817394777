import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import LanguageProvider from "./context/LanguageContext";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </React.StrictMode>
);
