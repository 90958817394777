import React, { useState, useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import skills from "../data/skillsData"; // Ensure this is your skills data
import "../assets/styles/components/_skills.css";
import { FaChevronDown } from "react-icons/fa";

const Skills = () => {
  const [skillsVisible, setSkillsVisible] = useState(false);
  const { translations } = useContext(LanguageContext);

  return (
    <section id="skills" className="skills-section">
      <h2 className="skills-title">{translations.skills.title}</h2>
      <div
        className="click-icon-container"
        onClick={() => setSkillsVisible(!skillsVisible)}
      >
        <FaChevronDown size={50} className="click-icon" />
        <p>{translations.skills.clickToReveal}</p>
      </div>
      {skillsVisible && (
        <div className="skills-list">
          {skills.map((skill, index) => (
            <div className="skill-item" key={index}>
              <skill.icon size={60} className="skill-icon" />
              <p className="skill-name">{skill.name}</p>
              <div className="skill-level">
                <div
                  className="level-bar"
                  style={{ width: `${skill.level}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default Skills;
