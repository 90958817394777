const timelineData = [
  {
    year: "2022",
    title: "1 Semester",
    description:
      "Learned about Intro to Programming, Databases, and Digital Technologies.",
    courses: [
      {
        name: "Intro to Programming",
        description:
          "Learned about variables, data types, loops, conditional statements, functions, and the use of DOM functions to modify HTML and CSS.",
      },
      {
        name: "Databases",
        description:
          "Learned about Databases,what a relational database is, what it can be used for and how it differs from other forms of persistent storage.",
      },
      {
        name: "Digital Technologies",
        description:
          "Learned about how to use a computer in an efficient way, how information is encoded digitally, as well as how it is stored, processed and transferred by and between hardware and software.",
      },
      {
        name: "Creative web development/Project",
        description:
          "Learned about web development. How to effectively use HTML and CSS to create awn interactive website and adopt creative solutions with animation (CSS3 animation).",
      },
    ],
    /*githubLink: "https://github.com/yourusername/project1",*/
  },
  {
    year: "2023",
    title: "2 Semester",
    description:
      "Learned about Object-Oriented Programming, Cybersecurity, and Algorithms & Data Structures.",
    courses: [
      {
        name: "Object-Oriented Programming",
        description:
          "Learned how to define and apply specializations of classes through inheritance/interface/polymorphism. The student is also introduced to some key concepts in analysis and design in relation to the development of object-oriented code.",
      },
      {
        name: "Cybersecurity",
        description:
          "Learned how to analyze the threat picture and take appropriate security measures on our own computer, in our own home network and give reasoned advice in relation to setup and technology choices for websites.",
      },
      {
        name: "Algorithms & Data Structures",
        description:
          "Learned about algorithms and data structures that are central to the work of implementing and designing efficient computer systems. Covered also analysis of worst-case resource use, as well as central algorithms and data structures related to search and sorting.",
      },
    ],
    /*githubLink: "https://github.com/yourusername/project1",*/
  },
  {
    year: "2023",
    title: "3 Semester",
    description:
      "Learned about software design and development, web development and API, and backend development.",
    courses: [
      {
        name: "Software Design & Development",
        description: "Learned about software design and development.",
      },
      {
        name: "Web Development and API",
        description:
          "Gained knowledge in web development and APIs through hands-on experience. The course introduced best practices and essential tools like React for building modern web applications and APIs, emphasizing practical implementation in this evolving field",
      },
      {
        name: "Backend Development",
        description:
          "I learned to work with enterprise applications and frameworks, focusing on software structuring, debugging, and unit testing. I also gained skills in building applications for persistence, presentation, and business logic, while staying professionally updated through industry forums.(JAVA)",
      },
    ],
    /*githubLink: "https://github.com/yourusername/project1",*/
  },
  {
    year: "2024",
    title: "4 Semester",
    description:
      "Learned about C programming in Linux, Agile Project, and Unity Game Development.",
    courses: [
      {
        name: "C programming in Linux",
        description:
          "Gained a knowledge of Unix and Linux operating systems, including their history, architecture, and structure. I understand the C programming language, the build process (compiling and linking), and virtualization.",
      },
      {
        name: "Agile Project",
        description:
          "I gained deeper experience in managing larger projects using the agile framework Scrum, particularly for software development. Through planning and implementing a real-world project for mnemonic - company in an interdisciplinary group, I practiced modern agile techniques and tools.",
      },
      {
        name: "Uniy Game Development",
        description:
          "Learned to program in C# for Unity, navigate and master the Unity development environment, create and publish projects to various platforms, and add game mechanics through coding. I can also create, import, and apply 3D models, animations, textures, sounds, prefabs, and other assets within Unity.",
      },
    ],
    /*githubLink: "https://github.com/yourusername/project1",*/
  },
  {
    year: "2024",
    title: "Semester 5",
    description: "On going ...",
    courses: [
      {
        name: "Microservices",
        description: "Ongoing course on microservices.",
      },
      {
        name: "DevOps & Cloud Computing",
        description: "Onging course on DevOps and cloud computing.",
      },
      {
        name: "Research Methods",
        description: "Ongoing course on research methods.",
      },
    ],
    /*githubLink: "https://github.com/yourusername/project1",*/
  },
  {
    year: "2025",
    title: "Final Year Project",
    description: "Upcoming final year project.",
    courses: [
      {
        name: "Batchelor Project",
        description: "Upcoming final year project.",
      },
    ],
    /*githubLink: "https://github.com/yourusername/project1",*/
  },
];

export default timelineData;
