import React, { useContext, useEffect, useRef } from "react";
import { LanguageContext } from "../context/LanguageContext";
import profileImg from "../assets/images/profile.png";
import "../assets/styles/components/_about.css";

const About = () => {
  const { translations } = useContext(LanguageContext);
  const aboutSectionRef = useRef(null); // Reference for the About section

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate"); // Add 'animate' class to each target
          } else {
            entry.target.classList.remove("animate"); // Remove 'animate' class when out of view
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.3, // Trigger when 10% of the section is visible
      }
    );

    const aboutSection = aboutSectionRef.current;
    if (aboutSection) {
      const elementsToAnimate = aboutSection.querySelectorAll(
        ".about-container, .about-image-container, .about-text, .about-title"
      );
      elementsToAnimate.forEach((element) => observer.observe(element));
    }

    return () => {
      if (aboutSection) {
        observer.disconnect(); // Clean up observer
      }
    };
  }, []);

  return (
    <section id="about" className="about-section" ref={aboutSectionRef}>
      <div className="about-container">
        <div className="about-image-container">
          <img src={profileImg} alt="Efrem Mickael" className="about-image" />
        </div>
        <div className="about-text">
          <h2 className="about-title">{translations.about.title}</h2>
          <p>{translations.about.description1}</p>
          <p>{translations.about.description2}</p>
        </div>
      </div>
    </section>
  );
};

export default About;
