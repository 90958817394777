import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext"; // Import the context
import "../assets/styles/components/_projects.css";
import projects from "../data/projectsData"; // Your project data

const Projects = () => {
  const { translations, language } = useContext(LanguageContext); // Get translations and language from context

  return (
    <section id="projects" className="projects-section">
      <h2 className="projects-title">{translations.projects.title}</h2>
      <div className="projects-list">
        {projects.map((project, index) => (
          <div className="project-item" key={index}>
            <img
              src={project.image}
              alt={project.title[language]}
              className="project-image"
            />
            <div className="project-content">
              <h3 className="project-title">{project.title[language]}</h3>
              {/* Use the current language to show the translated description */}
              <p className="project-description">
                {project.description[language]}
              </p>
              <a
                href={project.link}
                className="btn project-btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translations.projects.viewProject}
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;
