// src/context/LanguageContext.js
import React, { useState, createContext } from "react";
import enTranslations from "../assets/translations/en.json";
import noTranslations from "../assets/translations/no.json";

// Create the context
export const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("no");

  // Toggle between languages
  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "no" ? "en" : "no"));
  };

  // Choose the correct translations
  const translations = language === "no" ? noTranslations : enTranslations;

  return (
    <LanguageContext.Provider
      value={{ language, translations, toggleLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
