import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import About from "../components/About";
import Projects from "../components/Projects";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Skills from "../components/Skills";
import Timeline from "../components/Timeline";

const Home = ({ translations }) => {
  return (
    <>
      <Header translations={translations} />
      <Hero translations={translations} />
      <About translations={translations} />
      <Skills translations={translations} />
      <Projects translations={translations} />
      <Timeline />
      <Contact translations={translations} />
      <Footer translations={translations} />
    </>
  );
};

export default Home;
